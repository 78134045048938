// Core app JS
(function($, TS) {

  // see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
  // for more info
  function storageAvailable(type) {
    var storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
          // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  }

  TS.user.set = function(key, val, expires) {

    if (typeof expires == 'undefined' && storageAvailable('localStorage')) {
      localStorage.setItem(key, val);
    } else {
      Cookies.set(key, val, { expires: expires });
    }

  };

  TS.user.get = function(key) {

    if (storageAvailable('localStorage') && localStorage.getItem(key)) {
      return localStorage.getItem(key);
    } else {
      return Cookies.get(key);
    }

  };

}(jQuery, TS));
