(function ($, window, _, TS) {

  $(document).ready(function() {
    if (TS.user.get('tp_newsletter_signed_up')) {
      $('.newsletter-signup').remove();
    }
  });//end document.ready
  
  $(function() {
    // Checkout newsletter sign-up
    $('.js-payment-button').on('click', function() {
      var paymentForm = $('#payment_address_fields');
      
      if ( paymentForm ) {
        var newsletterCheck = paymentForm.find('#payment_address_newsletter');
        var email = paymentForm.find('input[type=email]').val();
        
        console.log(newsletterCheck.prop("checked"));
        if (!(newsletterCheck.prop("checked") == true)) {
          return;
        }
        
        if ( !validateEmail(email) ) {
          return;
        }
        
        var formData = {};
        formData['email'] = email;
        
        newsletterSignup(formData);
      }
    });
    
    // Footer newsletter sign-up
    $('#newsletter-signup-footer').on('submit', function(e) {

      e.preventDefault();
      
      var formData = {};
      var email = $(this).find('input[type=email]').val();
      var source = $(this).data('source');
      var pref = $(this).data('pref');
    
      if (!validateEmail(email)) {
        return;
      }

      formData['email'] = email;
      
      if (source) {
        formData['source'] = source;
      }

      if (pref) {
        formData['pref'] = pref;
      }
      
      $funcSuccess = function () {
        $('#newsletter-signup-footer').replaceWith('<p>Thank you for subscribing!</p>');
      }
      
      $funcError = function () {
        $('#newsletter-signup-footer').replaceWith('<p>Our email system is experiencing problems.  Please try again later.</p>');
      };

      var handlers = {success: $funcSuccess, error: $funcError};

      newsletterSignup(formData, handlers);
    
    });
    
    //skip to main content
    $(".skip-main").click(function() {

      $('html, body').animate({
          scrollTop: $('#main-landmark').offset().top
      }, 750).promise().then (function() { 
        focusElem = $( "#main-landmark" ).next();
        focusElem[0].tabIndex = -1; 
        focusElem[0].focus();
      });
    });
  
    var $nav_menu = $('#menu ul.nav'),
    $nav_menus = $('#menu ul.nav li.dropdown'),
    $nav_focused_children,
    $nav_focus = 0,
    $child_focus = -1;   
    
    //aria-expanded in nav
    $nav_menus.on('mouseenter focus', function(e) {
      this.setAttribute('aria-expanded', 'true');
      $(this).addClass('show-me');
      $nav_focused_children = $('#'+this.getAttribute('aria-controls')+ ' a');
    });

    $nav_menus.on('mouseleave', function(e) {
      this.setAttribute('aria-expanded', 'false');
      $(this).removeClass('show-me');
      $nav_focused_children = null;
    });
    
    $nav_menu.on("keydown", function (e) {
      //navigate across main items
      switch (e.keyCode) {
        case 39://right
        case 37://left
          $nav_menus[$nav_focus].setAttribute('aria-expanded', 'false');
          $($nav_menus[$nav_focus]).removeClass('show-me');
          $child_focus = -1;
          // Move right
          if (e.keyCode === 39) {
            $nav_focus++;
            // If we're at the end, go to the start
            if ($nav_focus >= $nav_menus.length) {
              $nav_focus = 0;
            }
            // Move left
          } else if (e.keyCode === 37) {
            $nav_focus--;
            // If we're at the start, move to the end
            if ($nav_focus < 0) {
              $nav_focus = $nav_menus.length - 1;
            }
          }

          $nav_menus[$nav_focus].focus();

          break;
        case 38://up
        case 40://down
          //navigate in sub items of main nav
          if ($nav_focused_children && $nav_focused_children.length) {
            // Move down
            if (e.keyCode === 40) {
              $child_focus++;
              // If we're at the end, go to the start
              if ($child_focus >= $nav_focused_children.length) {
                $child_focus = 0;
              }
              // Move up
            } else if (e.keyCode === 38) {
              $child_focus--;
              // If we're at the start, move to the end
              if ($child_focus < 0) {
                $child_focus = $nav_focused_children.length - 1;
              }
            }

            $nav_focused_children[$child_focus].focus();
          }

          break;
        case 9://tab
          $nav_menus[$nav_focus].setAttribute('aria-expanded', 'false');
          $($nav_menus[$nav_focus]).removeClass('show-me');
          $nav_focused_children = null;
          $child_focus = -1;
          break;
      }
    });
  
  });
  
  function validateEmail (email) {
    // Temporary email validation
    if (!email.match(/(.+)@(.+){2,}\.(.+){2,}/)) {
      return false;
    }
    
    return true;
  }
  
  function newsletterSignup(formData, handlerFunctions) {

    if (typeof handlerFunctions === 'undefined') {
      handlerFunctions = [];
    }
        
    $.ajax({
      type: 'POST',
      url: 'index.php?route=extension/module/tpnewsletter/subscribe',
      data: formData,
      dataType: "json",
      error: function(result){
        if (typeof handlerFunctions.error === "function") {
          handlerFunctions.error();
        }
        console.log(result);
      },
      success: function(result){
        if (result.success) {
          TS.user.set('tp_newsletter_signed_up', true);
          if (typeof handlerFunctions.success === "function") {
            handlerFunctions.success();
          }
        } else {
          
          if (typeof handlerFunctions.error === "function") {
            handlerFunctions.error();
          }
          console.log(result);
        }

      }
    });

  }

  
})(jQuery, window, _, TS);